import { apis } from '@doltech/core/lib/api/api.config';
import { apiUrls } from '@doltech/core/lib/api/api.urls';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';

import { useQuery } from 'react-query';
import moment from 'moment';

export const useGetReferralCode = () => {
  const { isLoggedIn } = useGetAuthInfo();
  const referralQuery = useQuery<any>(
    ['GET_MY_PREMIUM_USER_INFO'],
    async () => {
      return apis.membership.get<any>(apiUrls.membership.GET_MY_PREMIUM_USER_INFO);
    },
    {
      enabled: isLoggedIn,
      staleTime: 10000,
    }
  );
  return {
    query: referralQuery,
    referralCode: referralQuery?.data?.data?.referralCode || '...',
    isExpired: referralQuery?.data?.data?.dpStatus === 'EXPIRED',
    remainingDays: moment(referralQuery?.data?.data?.dpExpireAt).diff(moment(), 'days') || 1,
  };
};
