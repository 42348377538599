import * as React from 'react';
import { ExtendModalContent } from '../ui/ExtendModalContent/ExtendModalContent';
import { useGlobalModalStore } from '../../../../hocs/withGlobalModal';

export const useExpiredModal = () => {
  const { show } = useGlobalModalStore();

  const openExtendPremiumModal = () => {
    show({
      content: <ExtendModalContent />,
      title: null,
      closable: true,
      maskClosable: true,
      width: 'max-content',
      centered: true,
      modalStyle: 'unstyled-full-height',
    } as any);
  };

  return { openExtendPremiumModal };
};
