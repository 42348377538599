import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { ButtonSizableContent } from '../../../../../Button/ButtonGeneral/ButtonSizableContent';
import { ButtonStyles } from '../../../../../Button/ButtonStyles/ButtonStyles';
import { colorsV2 } from '../../../../../colors-v2';
import { Link } from '../../../../../Link';
import { ResponsiveTypography } from '../../../../../Typography/v3/ResponsiveTypography';
import { s3ToCDN } from '@doltech/utils/lib/url';

import * as React from 'react';
import styled from 'styled-components';
import { ItemPricing } from '../../ItemPricing/ItemPricing';

const Main = styled.div`
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.primary100};
  border-radius: 12px;
  padding: 20px;

  .text {
    margin-top: 12px;

    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    gap: 16px;
  }

  .action-btn {
    margin-top: 20px;
  }

  ${fromScreen(768)} {
    padding: 32px;

    .text {
      gap: 20px;
    }

    .action-btn {
      margin-top: 40px;
    }
  }
`;

const BenefitWrapper = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 8px;
`;

const WHAT_WE_HAVE = [
  'IELTS Online Test',
  'Kho bài mẫu Writing/Speaking',
  'Chép chính tả',
  'Học Tiếng Anh qua tin tức',
  'Làm và nhận bài sửa Assignment',
  'Trợ lý Speaking ở nhà',
  'Exercise như giáo viên ở nhà ',
];

export const CardPricingDol = () => {
  return (
    <Main>
      <div className="content">
        <img
          src={s3ToCDN(
            'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/Logo_4beae402d9.png'
          )}
          alt="DOL-Logo"
        />
        <div className="text">
          <ResponsiveTypography.Paragraph variant="semi-bold/14-20" color="black100">
            Học viên DOL
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph variant="bold/24-32" color="black100">
            Miễn Phí
          </ResponsiveTypography.Paragraph>
          <BenefitWrapper>
            {WHAT_WE_HAVE.map((text, idx) => (
              <ItemPricing key={idx} text={text} />
            ))}
          </BenefitWrapper>
        </div>
      </div>
      <div className="action-btn">
        <Link href="https://m.me/dolenglish.ieltsdinhluc" target="_blank">
          <ButtonStyles
            buttonStyle="filled"
            color="primary"
            colorStyle="bold"
            content={<ButtonSizableContent text="Tư vấn miễn phí" />}
          />
        </Link>
      </div>
    </Main>
  );
};
