import * as React from 'react';
import { ButtonSize, ButtonSizeProps } from '../ButtonSize/ButtonSize';
import {
  ButtonContentGeneral,
  IconButtonContentGeneralProps,
} from '../ButtonContentGeneral/ButtonContentGeneral';

interface ButtonSizableContentProps
  extends Pick<ButtonSizeProps, 'size' | 'textOnly' | 'iconOnly'>,
    Pick<IconButtonContentGeneralProps, 'iconLeft' | 'iconRight' | 'text'> {
  loading?: boolean;
}

export const ButtonSizableContent = ({
  loading,
  size,
  text,
  iconLeft,
  iconRight,
  textOnly = false,
  iconOnly = false,
}: ButtonSizableContentProps) => {
  return (
    <ButtonSize
      size={size}
      textOnly={textOnly}
      iconOnly={iconOnly || (Boolean(iconLeft || iconRight) && !text)}
      content={
        <ButtonContentGeneral
          btnStyle="icon"
          text={text}
          iconLeft={iconLeft}
          iconRight={iconRight}
          withLeftIcon={Boolean(iconLeft)}
          withRightIcon={Boolean(iconRight)}
          loading={loading}
        />
      }
      verticalPadding
    />
  );
};
