import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { IconOtherClose } from '@doltech/icons/IconOtherClose';
import { colorsV2 } from '../../../../colors-v2';

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  position: absolute;
  top: 16px;
  right: 16px;
  width: 36px;
  height: 36px;

  border: 2px solid ${colorsV2.gray10};
  border-radius: 50%;
`;

const Container = styled.div`
  position: relative;
  padding: 24px 32px 8px;
  background-color: ${colorsV2.white100};

  &.with-divider {
    padding: 24px 32px 20px;
    border-bottom: 1px solid #f0f0f2;
  }
  ${fromScreen(768)} {
    border-radius: 16px 16px 0px 0px;
  }
`;

interface ExtendModalHeaderProps {
  divider?: boolean;
  headerContent?: React.ReactNode;
  closeButton?: boolean;
  onClose?: () => void;
  className?: string;
}

export const ExtendModalHeader = (props: ExtendModalHeaderProps) => {
  const { divider = false, headerContent, closeButton = false, onClose, className } = props;

  return (
    <Container className={cl('modal-header', { 'with-divider': divider }, className)}>
      {headerContent}
      {closeButton && (
        <CloseButton onClick={onClose}>
          <IconOtherClose size={20} color="black60" />
        </CloseButton>
      )}
    </Container>
  );
};
