import { ResponsiveTypography } from '../../../../Typography/v3/ResponsiveTypography';
import { IconCourseCheck } from '@doltech/icons/IconCourseCheck';

import * as React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  align-items: center;
  gap: 8px;
`;

interface ItemPricingProps {
  text: string;
}

export const ItemPricing = (props: ItemPricingProps) => {
  const { text } = props;
  return (
    <Main>
      <IconCourseCheck color="green100" size={16} />
      <ResponsiveTypography.Paragraph variant="regular/14-20" color="black100">
        {text}
      </ResponsiveTypography.Paragraph>
    </Main>
  );
};
