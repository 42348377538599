import { environment } from '@doltech/core/lib/environment/environment';
import { routingUrls } from '@doltech/core/lib/routing/routing.config';
import * as qs from 'qs';

import { SkillEnum } from './constants';
import { buildUrl } from './url';

export const openNewTab = (url) => {
  const win = window.open(url, '_blank');
  if (win) {
    win.focus();
  }
};

export const getCurrentURLQuery = () => {
  if (window) {
    return window.location.search;
  }
  return undefined;
};

export const getCurrentQueryObject = () => {
  return qs.parse(getCurrentURLQuery().split('?')[1], { plainObjects: true });
};

export const goToTestSectionPreview = (testSectionId: string, skill: SkillEnum | string) => {
  openNewTab(
    buildUrl(
      `${window.location.origin}${routingUrls.onlineTest.ROOT_PATH}`,
      skill === SkillEnum.READING
        ? routingUrls.onlineTest.ADMIN_READING_TEST_SECTIONS_PREVIEW_PATH(testSectionId)
        : routingUrls.onlineTest.ADMIN_LISTENING_TEST_SECTIONS_PREVIEW_PATH(testSectionId)
    )
  );
};

export const goToBlogReview = (blogId: string) => {
  openNewTab(
    buildUrl(
      `${window.location.origin}${routingUrls.onlineTest.ROOT_PATH}`,
      routingUrls.onlineTest.ADMIN_IELTS_BLOG_PREVIEW_PATH(blogId)
    )
  );
};

export const goToPreview = (path: string) => {
  openNewTab(buildUrl(`${window.location.origin}${routingUrls.onlineTest.ROOT_PATH}`, path));
};

export const goToTestPreview = (testId: string) => {
  openNewTab(
    buildUrl(
      `${window.location.origin}${routingUrls.onlineTest.ROOT_PATH}`,
      routingUrls.onlineTest.ADMIN_TEST_PREVIEW_PATH(testId)
    )
  );
};

export const goToDictationPreview = (dictationId: string) => {
  openNewTab(
    buildUrl(
      `${window.location.origin}${routingUrls.onlineTest.ROOT_PATH}`,
      routingUrls.onlineTest.ADMIN_PREVIEW_DICTATION_PATH(dictationId)
    )
  );
};

export const getPublicUrlPath = (privateS3Path: string) => {
  return `${environment.CDN_GET_PRIVATE_RESOURCE}/${privateS3Path}`;
};

export const getPreviewAudio = (privateS3Path: string) => {
  return `${environment.CDN_GET_PREVIEW_AUDIO}/${privateS3Path}`;
};
